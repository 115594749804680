const CartActionTypes = {
  ADD_ITEM: 'ADD_ITEM',
  CLEAR_CART: 'CLEAR_CART',
  CLEAR_ITEM_FROM_CART: 'CLEAR_ITEM_FROM_CART',
  REMOVE_ITEM: 'REMOVE_ITEM',
  TOGGLE_CART_HIDDEN: 'TOGGLE_CART_HIDDEN',
  SET_CART_FROM_FIREBASE: 'SET_CART_FROM_FIREBASE',
  UPDATE_CART_IN_FIREBASE: 'UPDATE_CART_IN_FIREBASE',
};

export default CartActionTypes;
